(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  let video;

  function handleDOMContentLoaded() {
    const trigger = document.querySelector('.video__close');
    if (trigger) {
      video = document.querySelector('.video');
      trigger.addEventListener('click', closeVideo);
    }
  }

  function closeVideo(e) {
    e.preventDefault();

    video.classList.add('video--hide');
  }
})();
